import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom'

import axios from 'axios';

import { CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import zIndex from "@mui/material/styles/zIndex";
import { borderColor } from "@mui/system";
import { CircularProgress } from '@mui/material';

import { IoMdArrowRoundBack } from "react-icons/io";
import { BiExport } from "react-icons/bi";
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import { CgNotes } from "react-icons/cg";
import { Link } from "react-router-dom";
import GaugeChart from 'react-gauge-chart'
import { FaCheckCircle } from "react-icons/fa";

import Chart from 'react-apexcharts'
import { sanitizeSortModel } from "@mui/x-data-grid/hooks/features/sorting/gridSortingUtils";
import { FaRegCopy } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Application = () => {

  const location = useLocation();

  const navigate = useNavigate()
  const [applicationId, setApplicationId] = useState('')
  const [userId, setUserId] = useState('')
  const [application, setApplication] = useState({})
  const [onLoading, setOnLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const [applicationIdCopied, setApplicationIdCopied] = useState(false);

  const [exportingPDF, setExportingPDF] = useState(false);

  const toaster = useRef()
  const exampleToast = (
    <CToast>
      <CToastBody>Success</CToastBody>
    </CToast>
  )

  const customStyles = {
    content: {
      top: '20%',
      left: '10%',
      width: '80%',
      right: 'auto',
      bottom: 'auto',
      height: '70%',
      backgroundColor: '#E1E1E1',
      borderRadius: 15,
      borderColor: 'yellow'
    },
  };

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };


  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {

    window.scrollTo(0, 0);

    setOnLoading(true);

    var arr = location.search.split('=');

    var theApplicationId = arr[1];

    setApplicationId(theApplicationId);

    loadApplicationDetails(theApplicationId);

  }, []);

  useEffect(() => {
    // console.log('onLoading', onLoading)

  }, [onLoading]);


  const loadApplicationDetails = async (theAppId) => {

    setOnLoading(true)

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/applications/getApplicationDetails/' + theAppId, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setApplication(response.data.application);

    setOnLoading(false);

  };


  const closeModal = async () => {

    setModalIsOpen(false);
  };

  const goBack = async () => {

    navigate('/applications')
  }

  const notifyApplicationIdCopied = async () => {

    toast('Application ID copied to the clipboard', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

  }

  const getLabelText = (value) => {

    if (value >= 0 && value <= 25) {
      return 'LOW';
    } else if (value > 25 && value <= 50) {
      return 'MEDIUM';
    } else if (value > 50 && value <= 75) {
      return 'HIGH';
    } else if (value > 75 && value <= 100) {
      return 'CRITICAL';
    } else {
      return '';
    }
  };


  const scansChartOptions = {
    labels: ['Queued', 'Running', 'Completed', 'Failed'],
    colors: ['#d3d8de', '#fde140', '#07be64', '#eb0a05'],
    legend: {
      position: 'right',
      offsetY: 0,
      height: 230,
    },
  };

  const scansChartSeries = [0, application.runningScansCount, application.completedScansCount, application.failedScansCount]; 

  console.log('scansChartSeries:', scansChartSeries)

  const issuesChartOptions = {
    labels: ['Critical', 'High', 'Medium', 'Low', 'Informational'],
    colors: ['#a0090f', '#fb5550', '#ffad3b', '#ffdc48', '#d3d8de'],
    legend: {
      position: 'right',
      offsetY: 0,
      height: 230,
    },
  };

  const issuesChartSeries = [application.criticalIssueCount, application.highIssueCount,
  application.mediumIssueCount, application.lowIssueCount, application.informationalIssueCount]; 

  console.log('issuesChartSeries:', issuesChartSeries)


  var riskRatingVal = 0;

  if (application.riskRating == 'CRITICAL') {

    riskRatingVal = 0.80;

  } else if (application.riskRating == 'HIGH') {

    riskRatingVal = 0.60;

  } else if (application.riskRating == 'MEDIUM') {

    riskRatingVal = 0.40;

  } else if (application.riskRating == 'LOW') {

    riskRatingVal = 0.15;

  }


  let commonIssueTypesChartOptions;
  let commonIssueTypesChartSeries;

  console.log('commonIssueTypesChartSeries:', commonIssueTypesChartSeries)


  if (application && application.mostCommonIssues && application.mostCommonIssues.length > 0) {


    commonIssueTypesChartOptions = {
      labels: [application.mostCommonIssues[0].issue, application.mostCommonIssues[1].issue, application.mostCommonIssues[2].issue,
      application.mostCommonIssues[3].issue, application.mostCommonIssues[4].issue],
      colors: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd'],
      legend: {
        position: 'bottom',
      },
    };

    commonIssueTypesChartSeries = [application.mostCommonIssues[0].count, application.mostCommonIssues[1].count, application.mostCommonIssues[2].count,
    application.mostCommonIssues[3].count, application.mostCommonIssues[4].count];

  }


  return (
    <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>

      <>

        {onLoading ?
          <ShimmerTable row={8} col={10} />
          :

          <div style={{
            width: '100%', marginLeft: '0%', marginRight: '10%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
          }}>

            <div style={{ marginBottom: '2rem', }}>
              <h2>{application.name}</h2>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span style={{ fontSize: 13, color: 'white' }}>Application ID : {application._id}</span>
                <CopyToClipboard text={applicationId}
                  onCopy={() => notifyApplicationIdCopied()}>

                  <FaRegCopy color="#fff" style={{ cursor: 'pointer', marginLeft: 20 }} />

                </CopyToClipboard>
              </div>

              <hr />
              <span style={{ fontSize: 15, color: 'white' }}>Application Type : {application.type}</span><br />
              <span style={{ fontSize: 15, color: 'white' }}>Business Owner : {application.businessOwner ? (application.businessOwner.firstName + ' ' + application.businessOwner.firstName) : ''}</span><br />
              <span style={{ fontSize: 15, color: 'white' }}>Business Unit : {application.businessUnit ? application.businessUnit.name : ''}</span><br />
              <span style={{ fontSize: 15, color: 'white' }}>Asset Group : {application.assetGroup ? application.assetGroup.name : ''}</span><br />
              <hr />


            </div>


            <div style={{ display: 'flex', flexDirection: 'column' }}>

              <CButton
                onClick={goBack}
                style={{
                  width: 300,
                  marginBottom: '2%',
                  borderWidth: 0,
                  fontSize: 20,
                  borderColor: '#ffffff',
                  borderWidth: 1,
                  color: '#ffffff',
                  background: 'transparent'
                }}
                color="primary"
                className="px-3"
              >
                <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} /> Back to Applications
              </CButton>


            </div>
          </div>

        }


        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: 30 }}>

          <div style={{
            width: '30%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column', height: 400,
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }

            {!onLoading && riskRatingVal !== 0 ?


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#000', textAlign: 'center' }}>Risk Rating</h4>
                  <hr />

                  <GaugeChart id="gauge-chart3"
                    nrOfLevels={4}
                    colors={['#a4ba0d', '#fec81a', '#f85356', '#a10b0d']}
                    arcWidth={0.3}
                    percent={riskRatingVal}
                    formatTextValue={(value) => getLabelText(value)}
                  />


                </div>


              </div>
              :
              <>
                <h4 style={{ color: '#000', textAlign: 'center' }}>Risk Rating</h4>
                <hr />
                <span style={{ textAlign: 'center', marginTop: '30%' }}>No data found</span>
              </>
            }

          </div>



          <div style={{
            width: '30%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15, height: 400,
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }

            {!onLoading && !(scansChartSeries[0] == 0 && scansChartSeries[1] == 0 && scansChartSeries[2] == 0 && scansChartSeries[3] == 0) ?


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#000', textAlign: 'center' }}>Scans</h4>
                  <hr />

                  <Chart
                    options={scansChartOptions}
                    series={scansChartSeries}
                    type="donut"
                    height={300}
                  />


                </div>


              </div>
              :
              <>
                <h4 style={{ color: '#000', textAlign: 'center' }}>Scans</h4>
                <hr />
                <span style={{ textAlign: 'center', marginTop: '30%' }}>No data found</span>
              </>
            }

          </div>



          <div style={{
            width: '30%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15, height: 400,
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }
            {!onLoading && !(issuesChartSeries[0] == 0 && issuesChartSeries[1] == 0 && issuesChartSeries[2] == 0 && issuesChartSeries[3] == 0 && issuesChartSeries[4] == 0) ?


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#000', textAlign: 'center' }}>Issues</h4>
                  <hr />

                  <Chart
                    options={issuesChartOptions}
                    series={issuesChartSeries}
                    type="donut"
                    height={300}
                  />



                </div>
              </div>
              :
              <>
                <h4 style={{ color: '#000', textAlign: 'center' }}>Issues</h4>
                <hr />
                <span style={{ textAlign: 'center', marginTop: '30%' }}>No data found</span>
              </>
            }

          </div>



          <div style={{
            width: '30%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15, height: 400,
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }
            {!onLoading && commonIssueTypesChartSeries ?


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10, }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#000', textAlign: 'center' }}>Most Common</h4>
                  <hr />

                  <Chart
                    options={commonIssueTypesChartOptions}
                    series={commonIssueTypesChartSeries}
                    type="donut"
                    height={300}
                  />

                </div>
              </div>

              :

              <>

                <h4 style={{ color: '#000', textAlign: 'center' }}>Most Common</h4>
                <hr />
                <span style={{ textAlign: 'center', marginTop: '30%' }}>No data found</span>
              </>
            }

          </div>


          <div style={{
            width: '30%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15, height: 400,
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }
            {!onLoading &&


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#000', textAlign: 'center' }}>Policies</h4>
                  <hr />

                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center', alignSelf: 'center' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <FaCheckCircle style={{ color: 'green', marginRight: 10 }} size={20} />
                      <text style={{ color: '#fff' }}>OWASP Top 10</text>


                    </div>

                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <FaCheckCircle style={{ color: 'green', marginRight: 10 }} size={20} />
                      <text style={{ color: '#fff' }}>SANS 25</text>


                    </div>

                    <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <FaCheckCircle style={{ color: 'green', marginRight: 10 }} size={20} />
                      <text style={{ color: '#fff' }}>PCI DSS</text>

                    </div>

                  </div>

                </div>
              </div>

            }

          </div>


          <div style={{
            width: '30%', marginLeft: 0, marginRight: 20, display: 'flex', flexDirection: 'column',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15, height: 400,
          }}>



            {onLoading &&

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 100 }}>

                <ShimmerCircularImage size={500} />
                <ShimmerCircularImage size={500} />


              </div>
            }
            {!onLoading &&


              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 10 }}>



                <div style={{ width: '100%' }}>

                  <h4 style={{ color: '#000', textAlign: 'center' }}>Recent Scans</h4>
                  <hr />


                  {application.fiveRecentScans && application.fiveRecentScans.map((scan, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between', margin: '10px 0' }}>
                      <Link to={`/scan-details?id=${scan._id}`} style={{ color: '#fff', backgroundColor: 'transparent', marginRight: '10px' }}>{scan.scanName}</Link>
                      <span style={{ fontSize: 13 }}>{scan.scanCompletedAt ? 'Completed' : 'Running'}</span>
                    </div>
                  ))}




                </div>




              </div>



            }

            {application.fiveRecentScans && application.fiveRecentScans.length == 0 &&
              <>
                <span style={{ textAlign: 'center', alignSelf: 'center', marginTop: '30%' }}>No data found</span>
              </>
            }

          </div>

        </div>

      </>

    </div>
  )
}

export default Application



