import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom'

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect,
  CTextarea
} from '@coreui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ShimmerTable } from "react-shimmer-effects";


const EditApplication = () => {

  const navigate = useNavigate()
  const location = useLocation();

  const [applicationName, setApplicationName] = useState("")
  const [email, setEmail] = useState("")
  const [collectionUrl, setCollectionUrl] = useState("")
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  const [applications, setApplications] = useState([])
  const [language, setLanguage] = useState('')

  const [users, setUsers] = useState([])
  const [businessUnits, setBusinessUnits] = useState([])
  const [assetGroups, setAssetGroups] = useState([])

  const [application, setApplication] = useState({})

  const [applicationType, setApplicationType] = useState('Website')
  const [assetGroup, setAssetGroup] = useState('')
  const [businessUnit, setBusinessUnit] = useState('')
  const [businessOwner, setBusinessOwner] = useState('')
  const [businessImpact, setBusinessImpact] = useState('HIGH')
  const [testingStatus, setTestingStatus] = useState('IN PROGRESS')
  const [url, setURL] = useState('')
  const [host, setHost] = useState('')
  const [description, setDescription] = useState('')

  const [validationFailed, setValidationFailed] = useState(false);
  const [errorText, setErrorText] = useState('');

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const toaster = useRef();

  const appTypes = ['Website', 'Desktop Application', 'Mobile Application', 'Web Service', 'Others']
  const businessImpacts = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW']
  const testingStatuses = ['IN PROGRESS', 'COMPLETED', 'NOT STARTED', 'UNDER REVIEW']

  function checkURLType(url) {

    try {
      new URL(url);
    } catch (error) {
      return false; // Invalid URL
    }

    const extension = url.split('.').pop().toLowerCase();

    if (extension === 'json') {
      return true; // Valid URL to a JSON file
    } else if (extension === 'yaml' || extension === 'yml') {
      return true; // Valid URL to a YAML file
    } else {
      return false; // Valid URL, but not a JSON or YAML file
    }
  }


  useEffect(() => {

    window.scrollTo(0, 0);

    //setOnLoading(true);

    var arr = location.search.split('=');

    var theApplicationId = arr[1];

    setApplicationId(theApplicationId);

    loadApplicationDetails(theApplicationId);

  }, []);
  

  useEffect(() => {

    fetchUsers();
    fetchBusinessUnits();
    fetchAssetGroups();

  }, []);


  const loadApplicationDetails = async (theApplicationId) => {


    setLoading(true);

    const data = {
      applicationId: theApplicationId,
    };

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/applications/getApplicationDetails/'+theApplicationId, {
      headers: { Authorization: `Bearer ${token}` },
    });

    console.log('response.data.name', response.data.application.name)

    setApplication(response.data.application);
    setApplicationName(response.data.application.name)
    setApplicationType(response.data.application.type)
    setAssetGroup(response.data.application.assetGroup)
    setBusinessUnit(response.data.application.businessUnit)
    setBusinessOwner(response.data.application.businessOwner)
    setBusinessImpact(response.data.application.businessImpact)
    setTestingStatus(response.data.application.testingStatus)
    setURL(response.data.application.url)
    setHost(response.data.application.host)
    setDescription(response.data.application.description)

    setLoading(false);

  };


  const fetchUsers = async () => {

    setLoading(true);


    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/organizations/getOrganizationUsers`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setUsers(response.data.users);

    if (response.data.users.length > 0) {
      setBusinessOwner(response.data.users[0]._id)
    } else {
      setBusinessOwner('');
    }


    setLoading(false);
  };


  const fetchBusinessUnits = async () => {

    setLoading(true);


    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/organizations/getBusinessUnits`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setBusinessUnits(response.data.businessUnits);

    if (response.data.businessUnits.length > 0) {
      setBusinessUnit(response.data.businessUnits[0]._id)
    } else {
      setBusinessUnit('');
    }


    setLoading(false);
  };


  const fetchAssetGroups = async () => {

    setLoading(true);


    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/organizations/getAssetGroups`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setAssetGroups(response.data.assetGroups);

    if (response.data.assetGroups.length > 0) {
      setAssetGroup(response.data.assetGroups[0]._id)
    } else {
      setAssetGroup('');
    }


    setLoading(false);
  };



  const editApplication = () => {

    if (applicationName === '') {

      setValidationFailed(true);
      setErrorText('Application name is required.');

    } else {


      setSubmitting(true)

      const bearerToken = localStorage.getItem('ASIToken');

      // Create a FormData object
      const formData = new FormData();
      formData.append('name', applicationName);
      formData.append('type', applicationType);
      formData.append('businessImpact', businessImpact);
      formData.append('businessUnit', businessUnit);
      formData.append('testingStatus', testingStatus);
      formData.append('description', description);
      formData.append('url', url);
      formData.append('host', host);
      formData.append('businessOwner', businessOwner);

      console.log('name', applicationName);
      console.log('type', applicationType);
      console.log('businessImpact', businessImpact);
      console.log('businessUnit', businessUnit);
      console.log('testingStatus', testingStatus);
      console.log('description', description);
      console.log('url', url);
      console.log('host', host);
      console.log('businessOwner', businessOwner);

      console.log('application:',application)

      const requestData = {
        id:application._id,
        name: applicationName,
        type: applicationType,
        businessImpact: businessImpact,
        businessUnit: businessUnit,
        testingStatus: testingStatus,
        description: description,
        url: url,
        host: host,
        businessOwner: businessOwner,
    };


      // Make the API call
      fetch(global.backendUrl + '/api/v1/applications/editApplication', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {

          // Handle the API response

          if (data.hasOwnProperty('error')) {
            setValidationFailed(true);
            setErrorText(data.error);
            setSubmitting(false);
          }
          else if (data.hasOwnProperty('err')) {
            setSubmitting(false);
            setValidationFailed(true);
            setErrorText("Something went wrong. Please try again");

          } else {

            setSubmissionSuccess(true);
            setSubmitting(false);

            toast('Application updated', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });


            navigate('/applications')
          }

        })
        .catch(error => {
          // Handle any errors
          console.error(error);
        });      

    }

  }



  const goBack = (e) => {

    e.preventDefault();
    navigate('/applications')
  }


  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>

      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <h2>Editing Application</h2>
            <CButton
              onClick={goBack}
              style={{
                width: 300,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 20,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Applications List
            </CButton>
          </div>

          {loading ?
            <ShimmerTable row={8} col={10} />
          :



          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Application Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Application Name"
                autoComplete="applicationName"
                className="white-input"
                onChange={(e) => setApplicationName(e.target.value)}
                value={applicationName}
                style={{ width: '100%' }}
              />


            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Application Type</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="applicationType"
                className="white-input"
                onChange={(e) => setApplicationType(e.target.value)}
                value={applicationType}
                style={{ width: '100%' }}
              >
                {appTypes.map(appType => (
                  <option key={appType} value={appType}>
                    {appType}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>



            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Unit</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessUnit"
                className="white-input"
                onChange={(e) => setBusinessUnit(e.target.value)}
                value={businessUnit}
                style={{ width: '100%' }}
              >
                {businessUnits.map(businessUnit => (
                  <option key={businessUnit._id} value={businessUnit._id}>
                    {businessUnit.name}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Asset Group</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="assetGroup"
                className="white-input"
                onChange={(e) => setAssetGroup(e.target.value)}
                value={assetGroup}
                style={{ width: '100%' }}
              >
                {assetGroups.map(assetGroup => (
                  <option key={assetGroup._id} value={assetGroup._id}>
                    {assetGroup.name}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Owner</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessOwner"
                className="white-input"
                value={businessOwner}
                onChange={(e) => setBusinessOwner(e.target.value)}
                style={{ width: '100%' }}
              >
                {users.map(user => (
                  <option key={user._id} value={user._id}>
                    {user.firstName} {user.lastName}  ({user.email})
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>

            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Impact</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="businessImpact"
                className="white-input"
                value={businessImpact}
                onChange={(e) => setBusinessImpact(e.target.value)}
                style={{ width: '100%' }}
              >
                {businessImpacts.map(businessImpact => (
                  <option key={businessImpact} value={businessImpact}>
                    {businessImpact}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>



            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Testing Status</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormSelect
                id="testingStatus"
                className="white-input"
                value={testingStatus}
                onChange={(e) => setTestingStatus(e.target.value)}
                style={{ width: '100%' }}
              >
                {testingStatuses.map(testingStatus => (
                  <option key={testingStatus} value={testingStatus}>
                    {testingStatus}
                  </option>
                ))}
              </CFormSelect>
            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>URL</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="URL"
                autoComplete="url"
                className="white-input"
                value={url}
                onChange={(e) => setURL(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>


            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Host</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>

              <CFormInput
                placeholder="Host"
                autoComplete="host"
                className="white-input"
                value={host}
                onChange={(e) => setHost(e.target.value)}
                style={{ width: '100%' }}
              />


            </CInputGroup>

            <CFormLabel htmlFor="formTextarea" style={{ marginTop: 30, color: 'white' }}>Description</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <textarea
                id="formTextarea"
                placeholder="Description"
                autoComplete="description"
                className="form-control white-input"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{ width: '100%', resize: 'vertical', minHeight: '100px' }}
              />
            </CInputGroup>


            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={editApplication}
              disabled={loading}
            >


              {submitting ?
                <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                :
                'Save Application'
              }


            </CButton>


          </div>
}


        </div>
      </div>

    </div>
  )
}

export default EditApplication



