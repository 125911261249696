import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom'
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import { FaRegCopy } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";

import {CopyToClipboard} from 'react-copy-to-clipboard';

const Integrations = () => {

    const navigate = useNavigate()
    const [organization, setOrganization] = useState({})
    const [onLoading, setOnLoading] = useState(false);

    const [clientId, setClientId] = useState('');
    const [clientIdCopied, setClientIdCopied] = useState(false);

    const [clientSecret, setClientSecret] = useState('');
    const [clientSecretCopied, setClientSecretCopied] = useState(false);

    const toaster = useRef()
    
    const override: CSSProperties = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
    };

    useEffect(() => {

        window.scrollTo(0, 0);
    
        loadOrganizationDetails();
    
      }, []);
    
      useEffect(() => {
        // console.log('onLoading', onLoading)
    
      }, [onLoading]);
    
    
      const loadOrganizationDetails = async (theScanId) => {
    
        setOnLoading(true);
    
        const data = {
          scanId: theScanId,
        };
    
        const token = localStorage.getItem('ASIToken');
        const response = await axios.get('api/v1/organizations/getOrganizationDetails/', {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        setOrganization(response.data.organization);   
        setClientId(response.data.organization.clientId)      
        setClientSecret(response.data.organization.clientSecret)      
    
        setOnLoading(false);
    
      }; 


      const notifyClientIdCopied = async () => {

        toast('Client ID copied to the clipboard', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })

      }

      const notifyClientSecretCopied = async () => {

        toast('Client secret copied to clipboard', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })

      }
      


    return (

        <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
            <div style={{ width: '100%' }}>
                <div>
                    <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                        <h2 style={{ color: '#7366ff', fontWeight: 'bold' }}>Integrations</h2>
                        <hr />

                        {onLoading?
                        <ShimmerTable row={2} col={2} />
                        :

                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                            <div style={{ width: '60%' }}>


                              <div style={{display:'flex', flexDirection:'row'}}>
                                <span style={{fontWeight:'bold'}}>Client ID :</span> <span style={{marginLeft:10}}>{clientId}</span> 
                                <CopyToClipboard text={clientId}
                                    onCopy={() => notifyClientIdCopied()}>

                                    <FaRegCopy color="#fff" style={{cursor:'pointer', marginLeft:20}}/>

                                </CopyToClipboard>
                              </div>

                              <div style={{display:'flex', flexDirection:'row'}}>
                                <span style={{fontWeight:'bold'}}>Client Secret :</span> <span style={{marginLeft:10}}>{clientSecret}</span> 
                                <CopyToClipboard text={clientSecret}
                                    onCopy={() => notifyClientSecretCopied()}>

                                    <FaRegCopy color="#fff" style={{cursor:'pointer', marginLeft:20}}/>

                                </CopyToClipboard>
                              </div>

                               

                            </div>

                        </div>
}



                    </div>

                </div>
            </div>
        </div>
    )
}

export default Integrations